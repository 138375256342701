import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LazyLaod from "react-lazyload"
import {
  SectionPage,
  BreadCrumb,
  LeftPannel,
  RightPannel,
  MarkdownContent,
  Container,
  ExploreMore,
  SectionPageTitle,
  SectionDescription,
  SectionTitle,
} from "../components/Section"
import {
  HeroSection,
  HeroCover,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import CallToAction from "../components/CallToAction"
import SecondaryButton from "../components/Button/SecondaryButton"
import { graphql, Link } from "gatsby"
import CardSection from "../sections/Home/CardSection"
import StateCity from "../components/StateCity"
import PlusIcon from "../components/Icons/PlusIcon"
import QuickContactForm from "../components/QuickContactForm"
import FloatingReview from "../components/ReviewWdiget/floating"
import {
  DropWrapp,
  DropButton,
  DropButtonText,
  DropButtonImg,
  FadeContent,
} from "../components/FaqAccordion"

const StateLandingTemplate = ({ data, location }) => {
  const [currentFaq, setCurrentFaq] = useState()
  const handleOpen = i => {
    if (currentFaq === i) {
      setCurrentFaq()
    } else {
      setCurrentFaq(i)
    }
  }

  const AllProducts = data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  )
  const stateLandingData = data.contentfulStateLanding

  return (
    <Layout location={location}>
      <SEO
        title={stateLandingData.metaTitle}
        description={stateLandingData.metaDescription}
      />
      <HeroSection>
        <Container>
          <BreadCrumb className="static">
            <Link to="/">Home</Link> /{" "}
            <span> {stateLandingData.identifyName}</span>
          </BreadCrumb>
          <HeroCover textAlign="center" maxWidth="1120px" ml="auto" mr="auto" >
            <HeroPageTitle>{stateLandingData.heroTitle}</HeroPageTitle>
            <HeroDesc
              dangerouslySetInnerHTML={{
                __html:
                  stateLandingData.heroDescription.childMarkdownRemark.html,
              }}
            />
            <ExploreMore width="100%">
              <a
                href="tel:8777541818"
                aria-label="(877) 754-1818"
                className="btn-lg"
              >
                <SecondaryButton text="Call Us Now" />
              </a>
            </ExploreMore>
          </HeroCover>
        </Container>
      </HeroSection>

      <LazyLaod>
        <CardSection
          data={{
            title: "Check Out Our Top-Selling Metal Buildings",
            description: {},
          }}
          productData={AllProducts}
        />
      </LazyLaod>

      <StateCity
        stateName={stateLandingData.stateName}
        mapImage={stateLandingData.mapImage}
        cities={stateLandingData.cities}
        jsonCities={stateLandingData.jsonCities}
      />

      {/* <SectionPage
				ept="80px"
				epb="80px"
				xpt="40px"
				xpb="40px"
				pt="30px"
				pb="30px"
				bgBefore="#fff"
			>
				<Container>
					<SectionTitle>Areas We Serve In <strong>Florida</strong></SectionTitle>
					<SectionDescription textAlign="center"><p>We cover all locations throughout the state of <strong>Floria</strong>, including:</p></SectionDescription>
					<ListCity>
						{['Jacksonville','Tallahassee','Pensacola','Fort Lauderdale','St. Petersburg','Orlando','Port St. Lucie','Tampa','Hialeah'].map((item, index) =>
							<ListItemCity key={index}>{item}</ListItemCity>
						)}
					</ListCity>
					<ExploreMore><button type='button' className="btn-lg"><PrimaryButton text="Load More" /></button></ExploreMore>
				</Container>
			</SectionPage> */}

      <SectionPage
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bg="#F4FBFF"
      >
        <Container>
          <LeftPannel>
          <SectionDescription textAlign="left">
              <MarkdownContent 
                dangerouslySetInnerHTML={{
                  __html: stateLandingData.contents.childMarkdownRemark.html,
                }}
              />
            </SectionDescription>
            <SectionTitle textAlign="left">
            {data.contentfulStateLanding.faqTitle}
            </SectionTitle>
            <SectionDescription textAlign="left">
              <p>{data.contentfulStateLanding.faqDescription}</p>
            </SectionDescription>
            {data.contentfulStateLanding.faQs.map((item, i) => {
              return (
                <DropWrapp isVisible={currentFaq === i ? true : false}>
                  <DropButton
                    onClick={() => handleOpen(i)}
                    isVisible={currentFaq === i ? true : false}
                  >
                    <DropButtonText>{item.question}</DropButtonText>
                    <DropButtonImg isVisible={currentFaq === i ? true : false}>
                      <PlusIcon fill="#1E4156" />
                    </DropButtonImg>
                  </DropButton>
                  <FadeContent isVisible={currentFaq === i ? true : false}>
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                        __html: item.answer.childMarkdownRemark.html,
                      }}
                    />
                  </FadeContent>
                </DropWrapp>
              )
            })}
          </LeftPannel>
          <RightPannel>
            {/* <FloatingReview /> */}
            <HeroFormWrap>
              <HeroFormTitle>Get In Touch</HeroFormTitle>
              <HeroFormBody>
                <QuickContactForm
                  location={location}
                  formName="Florida Steel Building Contact Form"
                />
              </HeroFormBody>
            </HeroFormWrap>
            {/* <DesignPlan /> */}
            {/* <AccreditedBusiness /> */}
          </RightPannel>
        </Container>
      </SectionPage>

      {/* <SectionPage
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bg="#fff"
      >
        <Container>
          <SectionPageTitle textAlign="center">
            {data.contentfulStateLanding.faqTitle}
          </SectionPageTitle>
          <SectionDescription>
            <p>{data.contentfulStateLanding.faqDescription}</p>
          </SectionDescription>
          {data.contentfulStateLanding.faQs.map((item, i) => {
            return (
              <DropWrapp isVisible={currentFaq === i ? true : false}>
                <DropButton
                  onClick={() => handleOpen(i)}
                  isVisible={currentFaq === i ? true : false}
                >
                  <DropButtonText>{item.question}</DropButtonText>
                  <DropButtonImg isVisible={currentFaq === i ? true : false}>
                    <PlusIcon fill="#1E4156" />
                  </DropButtonImg>
                </DropButton>
                <FadeContent isVisible={currentFaq === i ? true : false}>
                  <MarkdownContent
                    dangerouslySetInnerHTML={{
                      __html: item.answer.childMarkdownRemark.html,
                    }}
                  />
                </FadeContent>
              </DropWrapp>
            )
          })}
        </Container>
      </SectionPage> */}
      <CallToAction />
    </Layout>
  )
}

export default StateLandingTemplate

export const pageQuery = graphql`
  query StateLandingPageQuery($id: String!) {
    contentfulStateLanding(id: { eq: $id }) {
      stateName
      abbreviation
      identifyName
      url
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      mapImage {
        fluid(maxWidth: 200, quality: 100) {
          base64
          sizes
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      cities {
        cityName
        url
      }
      jsonCities {
        content
      }
      contents {
        childMarkdownRemark {
          html
        }
      }
      faqTitle
      faqDescription
      faQs {
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    cities: allContentfulCityLanding {
      nodes {
        url
        cityName
      }
    }
    allContentfulProduct(sort: { fields: shopOrder, order: ASC }, limit: 6) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          description {
            description
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`
