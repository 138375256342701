import React, { useEffect } from "react"
import styled from "styled-components"
import SecondaryButton from "../Button/SecondaryButton"

const FloatingReviewBox = styled.div`
    margin:0 0 30px;
`
const ExploreMore = styled.div`
    display: block;
    width: fit-content;
    margin:15px auto 0 auto;
`

const FloatingWdiget = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://cdn.trustindex.io/loader.js?f1f2e7341af0240b4496be49996"
    script.async = true
    script.defer = true
    document.getElementById("floating-script").appendChild(script)
  }, [])
  return (
    <FloatingReviewBox>

      <div id="floating-script"></div>
      {/* <ExploreMore>
        <a
          href="https://g.page/r/CYAtY7rhWYH_EAI/review"
          target="_blank"
          rel="noreferrer"
        >
          <SecondaryButton text="Write a Review" />
        </a>
      </ExploreMore> */}
    </FloatingReviewBox>
  )
}

export default FloatingWdiget